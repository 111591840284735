import React, { Component } from "react";
import "./SiteLogo.css";

class SiteLogo extends Component {
  render() {
    return (
      <div className="SiteLogo">
        <img src="../img/potujno-logo.png" />
      </div>
    );
  }
}

export default SiteLogo;
