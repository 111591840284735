import React from "react";
import "./Home.css";

const Home = () => {
  return (
    <div className="Home">
      <div className="Description">
        <img src="../img/picture-1.png" />
        <p>
          Hello, we are a small team of software engineers based in the Seattle
          area. Our goal is to form reliable partnerships with local businesses,
          offering software solutions to niche and distinctive problems that are
          not solved by mainstream technology.
          <br />
          <br />
          We love to see how the things we develop positively influence people's
          lives. Leveraging our technical expertise, we aim to thoroughly
          explore your business operations, challenges, and aspirations in order
          to identify the most effective and innovative application of
          technology for your business.
        </p>
      </div>
      <div className="Contacts">
        <h4>CONTACT US</h4>
        <p>
          <a href="mailto:info@potujno.com">info@potujno.com</a><br />
          <b>George & Aliya Bogdanov</b>
        </p>
      </div>
    </div>
  );
};
export default Home;
