import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SiteLogo from "./SiteLogo";
// import Tabs from "./Components/TabComponent/Tabs";
// import AboutUs from "./Components/AllTabs/AboutUs";
// import Blog from "./Components/AllTabs/Blog";
// import ContactUs from "./Components/AllTabs/ContactUs";
import Home from "./Components/AllTabs/Home";
import { inject } from "@vercel/analytics";

function App() {
  inject();
  return (
    <div className="App">
      <BrowserRouter>
        <div className="Header">
          <SiteLogo />
          {/* <Tabs /> */}
        </div>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/home" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
